import { useState } from "react";
import C4Image from "../C4Image";
import { Dialog, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 8,
    cursor: "pointer",

    "&::before": {
      content: '""',
      display: "block",
      top: 0,
      right: 0,
      width: "40px",
      height: "40px",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      borderRadius: "50%",
      zIndex: 0,
    },

    "& svg": {
      zIndex: 1,
      height: "30px",
      width: "30px",
      color: "white",
      position: "absolute",
      top: 5,
      right: 5,
    },
  },
}));

const C8ImageGallery = ({ blok }: BASE.C8ImageGalleryProps) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const classes = useStyles();

  const handleClickOpen = (image: BASE.C4ImageProps["blok"]) => {
    setSelectedImage(image.src.filename ? image.src.filename : "");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  if (!blok.images) {
    return null;
  }

  return (
    <div className="c8-image-gallery grid-margin">
      <div className="gallery-group-1">
        {blok.images.slice(0, 2).map((image, i) => (
          <div
            className={`gallery-image-${i + 1}`}
            key={i}
            onClick={() => handleClickOpen(image)}
          >
            <C4Image blok={image} />
          </div>
        ))}
      </div>
      <div className="gallery-group-2">
        {blok.images.slice(2, 3).map((image, i) => (
          <div
            className={`gallery-image-${i + 3}`}
            key={i + 2}
            onClick={() => handleClickOpen(image)}
          >
            <C4Image blok={image} />
          </div>
        ))}
      </div>
      <Dialog
        open={open}
        onClose={() => null}
        fullWidth={true}
        maxWidth="lg"
        BackdropProps={{ style: { backgroundColor: "white" } }}
      >
        <div
          aria-label="close"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.69662 13.3034L13.3032 2.69678" stroke="white" />
            <path d="M2.69662 2.69662L13.3032 13.3032" stroke="white" />
          </svg>
        </div>
        {selectedImage && (
          <img src={selectedImage} alt="Selected" style={{ width: "100%" }} />
        )}
      </Dialog>
    </div>
  );
};

export default C8ImageGallery;
