import RichTextParser from "../../../../system/utils/RichTextParser";
import Link from "../../../library/Link";
import C4Image from "../C4Image";

const C3TextImage = ({ blok }: BASE.C3TextImageProps) => {
  return (
    <>
      <div
        className={`c3-text-image grid-margin ${blok.order}-first c3-block-${blok.order}-${blok.size}`}
      >

          <div className="image-block">
        {blok.image.map((image: any) => (
            <div className={`c4-${blok.order}-${blok.size}`}>
              {blok.tag && (
                <div className="c4-image-tag copy-small">{blok.tag}</div>
              )}
              <C4Image blok={{...image}} />
            </div>
          ))}
        </div>
        <div className="text-block">
          {blok.overline && (
            <div className="copy-small overline">{blok.overline}</div>
          )}
          {blok.link?.length ? (
            <a href={blok.link[0].link.url}>
              {blok.headline && <h2 className="c3-text-image-headline">{blok.headline}</h2>}
            </a>
          ) : (
            blok.headline && <h2 className="c3-text-image-headline">{blok.headline}</h2>
          )}
          {blok.copy?.content && blok.copy.content.length && (
            <div className="copy-default copy">
              {blok.copy.content.map((content) => {
                return <RichTextParser>{content}</RichTextParser>;
              })}
            </div>
          )}
          {blok.link &&
            blok.link.map((block: BASE.LinksProps) => (
              <Link
                key={block.link.url}
                link={block.link}
                label={block.label}
                openInNewTab={block.openInNewTab}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default C3TextImage;
