import React, { useState, useEffect } from "react";
import ClearIcon from "../../../icons/cross.svg";

const InputField = ({
  blok,
  inputRef,
  errors,
  onValueChange,
}: BASE.InputProps & {
  inputRef: React.Ref<any>;
  onValueChange: (label: string, value: string, isTouched: boolean) => void;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [value, setValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const handleInput = (event: React.ChangeEvent<any>) => {
    const newValue = event.target.value;
    setHasValue(newValue.trim() !== "");
    setValue(newValue);
    onValueChange(blok.label, newValue, isTouched);
  };

  useEffect(() => {
    onValueChange(blok.label, value, isTouched);
  }, [isTouched]);

  return (
    <div
      className={`input-field ${blok.type === "textarea" ? "text-area" : ""}`}
    >
      <div
        className={`input-field-label input-field-label${
          hasValue || isActive ? "-active" : ""
        }`}
      >
        {blok.label}
        {blok.required && <span className="input-field-required">*</span>}
      </div>
      {blok.type !== "textarea" && (
        <input
          ref={inputRef}
          onFocus={() => {
            setIsActive(true);
            setIsTouched(true);
          }}
          onBlur={() => {
            setIsActive(false);
            setIsTouched(true);
          }}
          required={blok.required ? true : false}
          style={{ outline: "none" }}
          onInput={handleInput}
          value={value}
          type={blok.type || "text"}
          maxLength={blok.type === "email" ? undefined : 30}
        />
      )}
      {blok.type === "textarea" && (
        <textarea
          rows={4}
          style={{ outline: "none" }}
          ref={inputRef}
          onFocus={() => {
            setIsActive(true);
            setIsTouched(true);
          }}
          onBlur={() => {
            setIsActive(false);
          }}
          required={blok.required ? true : false}
          onInput={handleInput}
          value={value}
        />
      )}
      {errors[blok.label] && (
        <div
          className="error copy-small"
          style={{ top: blok.type === "textarea" ? "180px" : "20px" }}
        >
          {errors[blok.label]}
        </div>
      )}
      {value && (
        <div
          className="clear"
          onClick={() => {
            setValue("");
            setIsActive(false);
            setIsTouched(false);
            setHasValue(false);
          }}
        >
          <img src={ClearIcon} />
        </div>
      )}
    </div>
  );
};

export default InputField;
