import { useEffect, useRef, useState } from "react";
import burgerOpen from "../../../../icons/open.svg";
import burgerClose from "../../../../icons/close.svg";
import arrowRight from "../../../../icons/arrowRight.svg";
import StoryblokClient from "storyblok-js-client";
import env from "react-dotenv";

const F1Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [data, setData] = useState<BASE.HeaderProps["blok"]>();
  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const Storyblok = new StoryblokClient({
    accessToken: env.STORYBLOK_TOKEN,
  });

  function fetchStory() {
    Storyblok.get("cdn/stories/global/f1header", {
      version: env.STORYBLOK_IS_PREVIEW === "yes" ? "draft" : "published",
    })
      .then((response) => {
        setData(response.data.story.content);
        if (!response.data.story.content) {
          return console.log("no response.data.story.content");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function checkContentRefHeight() {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = menuOpen
        ? `${contentRef.current.scrollHeight + 10}px`
        : "0px";
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      headerRef.current &&
      !headerRef.current.contains(event.target as Node) &&
      !!menuOpen
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    fetchStory();
    checkContentRefHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    checkContentRefHeight();

    if (menuOpen) {
      window.addEventListener("scroll", () => setMenuOpen(false));
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("scroll", () => setMenuOpen(false));
      window.addEventListener("click", handleClickOutside);
    };
  }, [menuOpen]);

  if (!data) {
    return <></>;
  }

  return (
    <header className="f1-header" key={data.logo.filename} ref={headerRef}>
      <div className="f1-wrapper">
        <a href={data.logoLink[0].link.cached_url}>
          <img
            className="f1-header-logo"
            src={data.logo.filename}
            alt={data.logo.alt}
          />
        </a>
        {screenWidth < 767 ? (
          <div className="f1header-dropdown">
            <div className="f1header-dropdown-burger" onClick={toggleMenu}>
              <img
                className={menuOpen ? "open" : "closed"}
                src={menuOpen ? burgerOpen : burgerClose}
                alt="navigation burger"
              />
            </div>
          </div>
        ) : (
          <div className="f1-header-nav-links">
            {data.navigationLinks.map(
              (navLink: BASE.LinksProps, index: number) => {
                const isActiveNav = (index: number) => {
                  if (window.location.pathname.slice(1) === "") {
                    return index === 0;
                  }
                  return (
                    window.location.pathname.slice(1) ===
                    data.navigationLinks[index].link.cached_url
                  );
                };
                return (
                  <a
                    key={index}
                    className={isActiveNav(index) ? "active" : ""}
                    href={navLink.link.cached_url}
                  >
                    {navLink.label}
                  </a>
                );
              }
            )}
          </div>
        )}
      </div>
      {screenWidth < 767 && (
        <div
          ref={contentRef}
          className={`f1-header-nav-links-dropdown ${menuOpen ? "open" : ""}`}
        >
          {data.navigationLinks.map(
            (navLink: BASE.LinksProps, index: number) => {
              const isActiveNav = (index: number) => {
                if (window.location.pathname.slice(1) === "") {
                  return index === 0;
                }
                return (
                  window.location.pathname.slice(1) ===
                  data.navigationLinks[index].link.cached_url
                );
              };
              return (
                <>
                  <a
                    key={index}
                    className={isActiveNav(index) ? "active" : ""}
                    href={navLink.link.cached_url}
                  >
                    {navLink.label}
                    <img
                      src={arrowRight}
                      alt="arrow right"
                      className="arrow-right"
                    />
                  </a>
                  {index !== data.navigationLinks.length - 1 && (
                    <div className="f1-header-nav-links-dropdown-split" />
                  )}
                </>
              );
            }
          )}
        </div>
      )}
    </header>
  );
};
export default F1Header;
