import StoryblokClient from "storyblok-js-client";
import env from "react-dotenv";

const fetchProjects = async () => {
  const Storyblok = new StoryblokClient({
    accessToken: env.STORYBLOK_TOKEN,
  });

  try {
    const response = await Storyblok.get("cdn/stories", {
      version: env.STORYBLOK_IS_PREVIEW === "yes" ? "draft" : "published",
    });
    const allStories = response.data.stories;
    const filteredStories = allStories.filter(
      (story: any) =>
        story.content.component === "P2Article" && story.content
    );
    if (!filteredStories.length) {
      console.log("no projects in the projects folder");
    }
    return filteredStories;
  } catch (error) {
    console.log("error", error);
    return [];
  }
};

export default fetchProjects;