import arrowRight from "../../../icons/arrowRight.svg";

const Link = ({ link, label, openInNewTab }: BASE.LinksProps) => {
  let url = "";
  switch (link.linktype) {
    case "url":
      if (link.url.includes("mailto:")) {
        url = link.url;
      } else {
        url = `https://${link.url}`;
      }
      break;
    case "story":
      url = link.cached_url ? link.cached_url : link.url;
      break;
    default:
      url = link.url;
  }
  return (
    <a
      className="copy-default-bold library-link"
      href={url}
      target={!!openInNewTab ? "_blank" : "_self"}
    >
      {label}
      {label && (
        <svg
          className="arrow-right"
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.51758 7.66162L13.8905 4.44383C13.9449 4.40386 13.9449 4.3227 13.8905 4.28274L9.51758 1.06494"
            stroke="black"
          />
          <path d="M13.8241 4.36328L0.490723 4.36328" stroke="black" />
        </svg>
      )}
    </a>
  );
};

export default Link;
