import C2Text from "../../content/C2Text";
import C4Image from "../../content/C4Image";

const F4About = ({ blok }: BASE.F4About) => {
  return (
    <div className="f4-about">
      {blok.image.map(
        (block: any) =>
          block.component === "C4Image" && (
            <div className="f4-about-image">
              <C4Image blok={{ ...block }} />
            </div>
          )
      )}
      {blok.text.map(
        (block: any) =>
          block.component === "C2Text" && (
            <div className="f4-about-text grid-margin">
              <C2Text blok={{ ...block }} />
            </div>
          )
      )}
    </div>
  );
};

export default F4About;
