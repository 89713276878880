import RichTextParser from "../../../../system/utils/RichTextParser";
import Button from "../../../library/Button";
import Link from "../../../library/Link";

const C2Text = ({ blok }: BASE.C2TextProps) => {
  return (
    <div
      className="c2-text"
      style={{ textAlign: blok.textAlign ?? "left" }}
    >
      {blok.headline && <h1>{blok.headline}</h1>}
      <div className="copy-default">
        {blok.copy.content.map((content) => {
          return <RichTextParser>{content}</RichTextParser>;
        })}
      </div>
      {blok.link &&
        blok.link.map((content, index) => {
          return <Link key={index} link={content.link} label={content.label} openInNewTab={content.openInNewTab} />;
        })}
      {blok.button &&
        blok.button.map((content) => {
          return <Button blok={content} />;
        })}
    </div>
  );
};
export default C2Text;
