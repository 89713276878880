import ContactFormColumn from "../../../library/ContactFormColumn";
import C2Text from "../../content/C2Text";
import C4Image from "../../content/C4Image";

const F3Contact = ({ blok }: BASE.F3Contact) => {
  return (
    <div className="f3-contact grid-margin">
      <div className="f3-contact-image-and-text">
        {blok.column.map(
          (block: any) =>
            block.component === "C4Image" && (
              <div className="f3-contact-image">
                <C4Image blok={{ ...block }} />
              </div>
            )
        )}
        {blok.column.map(
          (block: any) =>
            block.component === "C2Text" && (
              <div className="f3-contact-text">
                <C2Text blok={{ ...block }} />
              </div>
            )
        )}
      </div>
      <div className="f3-contact-form">
        {blok.column.map(
          (block: any) =>
            block.component === "ContactFormColumn" && (
              <ContactFormColumn blok={{ ...block }} />
            )
        )}
      </div>
    </div>
  );
};

export default F3Contact;
