import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import arrowRight from "../../../../icons/arrowRight.svg";
import clsx from "clsx";
import C4Image from "../C4Image";

const C5ImageSlider = ({ blok }: BASE.C5ImageSliderProps) => {
  const { background, images } = blok;
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [swiperOptions, setSwiperOptions] = useState<any>(null);

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.activeIndex);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{ background: background ?? "#E1ECF7" }}
      className="c5-image-slider"
    >
      <div className="custom-navigation">
        <div className="slide-indicators">
          {images.map((_, index) => (
            <div
              key={index}
              className={clsx("slide-indicator", {
                active: activeIndex === index,
              })}
            />
          ))}
        </div>
        {screenWidth > 767 && (
          <div className="custom-navigation-arrows">
            <img
              ref={prevRef}
              className={`swiper-button-prev arrow-left ${
                activeIndex === 0 ? "swiper-button-disabled" : ""
              }`}
              src={arrowRight}
              alt="arrow right"
              onClick={() => {
                setActiveIndex(swiperOptions.activeIndex);
                swiperOptions.slidePrev();
              }}
            />
            <img
              ref={nextRef}
              className={`swiper-button-next arrow-right ${
                images.length - 1 === activeIndex
                  ? "swiper-button-disabled"
                  : ""
              }`}
              src={arrowRight}
              alt="arrow right"
              onClick={() => {
                setActiveIndex(swiperOptions.activeIndex + 1);
                swiperOptions.slideNext();
              }}
            />
          </div>
        )}
      </div>
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        initialSlide={0}
        onSwiper={(swiper: any) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
          setSwiperOptions(swiper);
        }}
        onChange={(swiper: any) => setActiveIndex(swiper.activeIndex)}
        slidesPerView={screenWidth <= 767 ? 1 : 1.75}
        spaceBetween={screenWidth <= 767 ? 8 : 24}
        centeredSlides={screenWidth <= 767 ? true : false}
        onSlideChange={handleSlideChange}
        slidesOffsetBefore={screenWidth <= 767 ? 0 : 16}
        slidesOffsetAfter={screenWidth <= 767 ? 0 : 16}
        className="c5-image-slider-swiper"
      >
        {images.map((item: BASE.C4ImageProps["blok"], index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <div className="c5-image-slider-item">
              <C4Image blok={item} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default C5ImageSlider;
