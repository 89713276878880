import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

type P1GenericProps = {
  blok: {body: Array<BASE.Modules>};
};

const P1Generic = ({ blok }: P1GenericProps) => {
  return (
    <div {...storyblokEditable(blok)} className="px-4 grid-container">
      {blok.body
        ? blok.body.map((blok: any) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </div>
  );
};
export default P1Generic;
