import { useState } from "react";
import C6TimelineItem from "../C6TimelineItem";

const C6Timeline = ({ blok }: BASE.C6TimelineProps) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleExpand = (index: number) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="c6-timeline">
      {blok.headline && <h2 className="c6-timeline-headline">{blok.headline}</h2>}
      {blok.items.map((item, index) => (
        <C6TimelineItem
          blok={item}
          key={index}
          isExpanded={index === expandedIndex}
          toggleExpand={() => handleExpand(index)}
        />
      ))}
    </div>
  );
};
export default C6Timeline;
