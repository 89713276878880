import { useEffect, useRef, useState } from "react";
import ArrowRight from "../../../../icons/arrowRight.svg";
import C3TextImage from "../../content/C3TextImage";
import fetchProjects from "../../../../system/utils/fetchProjects";

const L1Projects = ({ blok }: BASE.L1ProjectsProps) => {
  const [items, setItems] = useState<any[]>([]);
  const [c3TextImageComponents, setC3TextImageComponents] = useState<BASE.C3TextImageProps[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  function handleFilterForTag(activeIndex: number) {
    if (activeIndex === 0) {
      return setC3TextImageComponents(items);
    } else {
      return setC3TextImageComponents(
        items.filter(
          (item: any) => item.content.teaserTag === allTags[activeIndex]
        )
      );
    }
  }
  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  const allTags = [
    "All",
    ...Array.from(
      new Set(
        items
          .map((item: any) => item.content.teaserTag)
          .filter((tag) => tag !== undefined)
      )
    ).filter((tag) => tag !== ""),
  ];

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", () => setDropdownOpen(false));
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("scroll", () => setDropdownOpen(false));
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const stories = await fetchProjects();
      setItems(stories);
      setC3TextImageComponents(stories);
    };

    fetchData();
  }, []);

  if (!items.length) {
    return null;
  }
  return (
    <div className="l1-projects">
      <div className="l1-projects-intro">
        <h2>{blok.headline}</h2>
        <div className="copy-default">{blok.copy}</div>
        {!!blok.showDropdown && (
          <div className="c10-filter-dropdown">
            <div className="dropdown-wrapper" ref={dropdownRef}>
              <div
                className="dropdown copy-large-bold"
                onClick={handleDropdown}
              >
                {allTags && allTags[activeIndex]}
                <img
                  src={ArrowRight}
                  alt="arrow"
                  className={`arrow-right dropdown-arrow ${
                    dropdownOpen
                      ? "dropdown-arrow-opened"
                      : "dropdown-arrow-closed"
                  }`}
                />
              </div>
              <div
                className={`dropdown-list ${
                  dropdownOpen ? "dropdown-list-open" : "dropdown-list-closed"
                }`}
              >
                {allTags &&
                  allTags.map((tag: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className={`dropdown-list-item copy-large${
                          index === activeIndex ? "-bold" : ""
                        }`}
                        onClick={() => {
                          setActiveIndex(index);
                          handleFilterForTag(index);
                          handleDropdown();
                        }}
                      >
                        {tag}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
      {c3TextImageComponents.map(
        (item: BASE.C3TextImageProps, index: number) => {
          if (!item.content) return null;
          const stageBlock = item.content.blocks?.find(
            (block: any) => block.component === "C1Stage"
          );
          return (
            <C3TextImage
              key={index}
              blok={{
                headline: item.content.teaserHeadline
                  ? item.content.teaserHeadline
                  : "",
                image: stageBlock ? stageBlock.image : item.content.teaserImage,
                copy: item.content.teaserCopy
                  ? item.content.teaserCopy
                  : { content: [] },
                tag: item.content.teaserTag ? item.content.teaserTag : "",
                order: item.content.teaserOrder
                  ? item.content.teaserOrder
                  : "image",
                size: item.content.teaserSize ? item.content.teaserSize : "s",
                overline: item.content.teaserOverline
                  ? item.content.teaserOverline
                  : stageBlock.topline,
                link: [
                  {
                    label: "see project",
                    link: { url: item.full_slug ?? "", linktype: "story" },
                  },
                ],
              }}
            />
          );
        }
      )}
    </div>
  );
};

export default L1Projects;
