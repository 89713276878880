const C9Tags = ({ blok }: BASE.C9TagsProps) => {
  return (
    <div className="c9-tags grid-margin">
      {blok.headline && <h2>{blok.headline}</h2>}
      {blok.subline && <h3>{blok.subline}</h3>}
      <div className="c9-tag-group">
        {blok.tags.map((tag: BASE.TagProps, index: number) => (
          <div key={index} className={"c9-tag"}>
            {tag.icon?.filename && (
              <img src={tag.icon.filename} alt={tag.icon.alt} />
            )}
            <div className={`copy-default${tag.fontBold ? "-bold" : ""}`}>
              {tag.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default C9Tags;
