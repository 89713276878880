import { useEffect, useState } from "react";
import StoryblokClient from "storyblok-js-client";
import env from "react-dotenv";

const F5EntryCard = ({
  onClick,
  overlayClose,
  hideArrow,
}: BASE.F5EntryCardProps) => {
  const [entryCardData, setEntryCardData] = useState<any>({});

  const Storyblok = new StoryblokClient({
    accessToken: env.STORYBLOK_TOKEN,
  });

  function fetchStory() {
    Storyblok.get("cdn/stories/global/f5entrycard", {
      version: env.STORYBLOK_IS_PREVIEW === "yes" ? "draft" : "published",
    })
      .then((response) => {
        setEntryCardData(response.data.story.content);
        if (!response.data.story.content) {
          return console.log("no response.data.story.content");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchStory();
  }, []);

  return (
    <div className={`overlay-open ${overlayClose ? "overlay-slide-up" : ""}`}>
      <div
        id="particle-container"
        className={entryCardData.background ?? 'dark'}
      ></div>
      <div className="overlay-content">
        {entryCardData.headline && (
          <h2
            className="overlay-content-headline"
            style={{ color: entryCardData.textColour ? entryCardData.textColour : entryCardData.background === 'dark' ? "white" : 'black' }}
          >
            {entryCardData.headline}
          </h2>
        )}
      </div>
      {!!entryCardData.showArrow && (
        <div
          className={`close-entrycard ${hideArrow ? "hide" : ""} ${entryCardData.background ?? 'dark'}`}
          onClick={() => onClick()}
        >
          <svg
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.51758 7.66162L13.8905 4.44383C13.9449 4.40386 13.9449 4.3227 13.8905 4.28274L9.51758 1.06494" />
            <path d="M13.8241 4.36328L0.490723 4.36328" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default F5EntryCard;
