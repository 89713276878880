import C4Image from "../C4Image";

const C1Stage = ({ blok }: BASE.StageProps) => {
  if (!blok.objectFit) {
    blok.objectFit = "cover";
  }
  return (
    <div
      className={`c1-stage ${
        blok.objectFit === "contain" ? "c1-stage-height-auto" : ""
      }`}
    >
      {blok.image.length &&
        blok.image.map((image: any, index: number) => {
          return (
            <C4Image
              key={index}
              blok={{ ...image }}
              className={`c1-stage-image c1-stage-image${
                blok.objectFit === "cover" ? "-cover" : "-contain"
              }`}
            />
          );
        })}
      {blok.headline && (
        <div className="desktop-text-wrapper">
          <div className="copy-small c1-stage-copy">{blok.topline}</div>
          <h2 className="c1-stage-headline">{blok.headline}</h2>
        </div>
      )}
    </div>
  );
};
export default C1Stage;
