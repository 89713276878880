import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import InputField from "../InputField";

const ContactFormColumn = ({ blok }: BASE.ContactFormColumnProps) => {
  const inputRefs = useRef<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>({});
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleInputChange = (
    label: string,
    value: string,
    isTouched: boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [label]: {
        value,
        isTouched,
        isRequired: blok.inputField.find((input) => input.label === label)
          ?.required,
      },
    }));
  };

  const checkForErrors = (
    formData: { [key: string]: any },
    buttonClicked?: boolean
  ) => {
    const newErrors: { [key: string]: string } = {};
    blok.inputField.forEach((input) => {
      const fieldData = formData[input.label] || {};
      const value = fieldData.value || "";
      const isTouched = fieldData.isTouched || false;

      if (buttonClicked && !isTouched && input.required) {
        newErrors[input.label] = `${input.label} is required`;
      }

      switch (input.type) {
        case "text":
          if (isTouched && input.required && !value) {
            newErrors[input.label] = `${input.label} is required`;
          }
          break;
        case "tel":
          const validateTel = (tel: string) => {
            return !/^[0-9]*$/.test(tel);
          };

          if (value) {
            if (validateTel(value)) {
              newErrors[input.label] = "Please enter numbers only";
            } else if (value.length < 10) {
              newErrors[input.label] =
                "Please enter a valid phone number (at least 10 digits)";
            }
          }
          break;
        case "email":
          const validateEmail = (email: string) => {
            return String(email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
          };
          if (isTouched && input.required && !value) {
            newErrors[input.label] = `${input.label} is required`;
          }
          if (value) {
            if (value.length > 35) {
              newErrors[input.label] = "Email address is too long";
            }
            if (!validateEmail(value)) {
              newErrors[input.label] = "Please enter a valid email address";
            }
          }
          break;
        default:
      }
    });

    setErrors(newErrors);
  };

  const noneHaveValue = (formData: { [key: string]: any }) => {
    return Object.keys(formData).every((key) => !formData[key].value);
  };

  const hasFormErrors =
    !!Object.keys(errors).length ||
    !!noneHaveValue(formData) ||
    Object.keys(formData).some(
      (key) => formData[key].isRequired && !formData[key].value
    );

  const generateMailtoLink = () => {
    if (!hasFormErrors) {
      const subject = `Lachstudio - Contact Request${
        formData["Email"]?.value ? ` from ${formData["Email"]?.value}` : ""
      }`;
      const body = Object.keys(formData)
        .filter((key) => formData[key].value)
        .map(
          (key) =>
            `${encodeURIComponent(key)}: ${encodeURIComponent(
              formData[key].value
            )}`
        )
        .join("%0D%0A");
      return `mailto:${blok.recipientAddress}?subject=${subject}&body=${body}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    checkForErrors(formData);
  }, [formData]);

  return (
    <form className="contact-form">
      <div className="contact-form-column">
        {blok.inputField.map((input) => (
          <InputField
            key={input.label}
            blok={input}
            inputRef={(el) => (inputRefs.current[input.label] = el)}
            errors={errors}
            onValueChange={handleInputChange}
          />
        ))}
      </div>
      <div className="contact-form-column-button">
        {blok.buttonLabel && (
          <Button
            disabled={hasFormErrors}
            blok={{
              link: [
                {
                  label: blok.buttonLabel,
                  link: { linktype: "mail", url: generateMailtoLink() },
                  openInNewTab: false,
                },
              ],
              variant: "primary",
              color: "darkblue",
            }}
            onClick={() => checkForErrors(formData, true)}
          />
        )}
      </div>
    </form>
  );
};

export default ContactFormColumn;
