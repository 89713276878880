import React from "react";
import ReactDOM from "react-dom/client";
import { storyblokInit, apiPlugin } from "@storyblok/react";

import App from "./App";

import P1Generic from "./templates/P1Generic";
import P2Article from "./templates/P2Article";

import C1Stage from "./components/modules/content/C1Stage";
import C2Text from "./components/modules/content/C2Text";
import C3TextImage from "./components/modules/content/C3TextImage";
import C4Image from "./components/modules/content/C4Image";
import C5ImageSlider from "./components/modules/content/C5ImageSlider";
import C6Timeline from "./components/modules/content/C6Timeline";
import C6TimelineItem from "./components/modules/content/C6TimelineItem";
import C7Video from "./components/modules/content/C7Video";
import C8ImageGallery from "./components/modules/content/C8ImageGallery";
import C9Tags from "./components/modules/content/C9Tags";

import F3Contact from "./components/modules/function/F3Contact";
import F4About from "./components/modules/function/F4About";

import T1Teaser from "./components/modules/teaser/T1Teaser";

import Button from "./components/library/Button";
import InputField from "./components/library/InputField";
import ContactFormColumn from "./components/library/ContactFormColumn";
import L1Projects from "./components/modules/list/L1Projects";
import env from "react-dotenv";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
storyblokInit({
  accessToken: env.STORYBLOK_TOKEN,
  enableFallbackComponent: true,
  use: [apiPlugin],
  components: {
    P1Generic: P1Generic,
    P2Article: P2Article,
    C1Stage: C1Stage,
    C2Text: C2Text,
    C3TextImage: C3TextImage,
    C4Image: C4Image,
    C5ImageSlider: C5ImageSlider,
    C6Timeline: C6Timeline,
    C6TimelineItem: C6TimelineItem,
    C7Video: C7Video,
    C8ImageGallery: C8ImageGallery,
    C9Tags: C9Tags,
    F3Contact: F3Contact,
    F4About: F4About,
    T1Teaser: T1Teaser,
    ContactFormColumn: ContactFormColumn,
    Button: Button,
    InputField: InputField,
    L1Projects: L1Projects,
  },
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
