import { useEffect, useState } from "react";
import C3TextImage from "../../content/C3TextImage";
import fetchProjects from "../../../../system/utils/fetchProjects";

const T1Teaser = ({ blok }: BASE.T1TeaserProps) => {
  const [allProjects, setAllProjects] = useState<any[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const stories = await fetchProjects();
      setAllProjects(stories);
    };
    fetchData();
  }, []);

  const projects = allProjects?.filter((item: any) => {
    return (
      item.uuid === blok.firstTeaserProject.id ||
      item.uuid === blok.secondTeaserProject.id
    );
  });

  return (
    <div className="t1-teaser grid-margin">
      {blok.headline && <h2>{blok.headline}</h2>}
      {blok.copy && <div className="copy-default">{blok.copy}</div>}
      {projects && projects.length !== 0 && (
        <div className="t1-teaser-projects">
          {projects.map((item: BASE.C3TextImageProps, index: number) => {
            if (!item.content) return null;
            const fallbackStageHeadline = item.content.blocks?.find(
              (block) => block.component === "C1Stage"
            ).headline;
            const fallbackStageTopline = item.content.blocks?.find(
              (block) => block.component === "C1Stage"
            ).topline;
            const fallbackStageImage = item.content.blocks?.find(
              (block) => block.component === "C1Stage"
            ).image;
            return (
              <C3TextImage
                key={index}
                blok={{
                  headline: item.content.teaserHeadline
                    ? item.content.teaserHeadline
                    : fallbackStageHeadline,
                  image:
                    !!item.content.teaserImage &&
                    item.content.teaserImage.length !== 0
                      ? item.content.teaserImage
                      : fallbackStageImage,
                  copy: item.content.teaserCopy
                    ? item.content.teaserCopy
                    : { content: [] },
                  tag: item.content.teaserTag ? item.content.teaserTag : "",
                  order: item.content.teaserOrder
                    ? item.content.teaserOrder
                    : "image",
                  size: item.content.teaserSize ? item.content.teaserSize : "s",
                  overline: item.content.teaserOverline
                    ? item.content.teaserOverline
                    : fallbackStageTopline,
                  link: [
                    {
                      label: "see project",
                      link: { url: item.full_slug ?? "", linktype: "story" },
                    },
                  ],
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
export default T1Teaser;
