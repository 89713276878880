import React, { useEffect, useRef } from "react";
import RichTextParser from "../../../../system/utils/RichTextParser";
import arrowRight from "../../../../icons/arrowRight.svg";

const C6TimelineItem = ({ blok, isExpanded, toggleExpand }: BASE.C6TimelineItemProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded ? `${contentRef.current.scrollHeight}px` : "0px";
    }
  }, [isExpanded]);

  return (
    <div className="c6-timeline-item" onClick={toggleExpand}>
      <div className="c6-timeline-item-titles">
        <h4>
          {blok.jobtitle} - {blok.companyName}
        </h4>
        <div className="copy-default c6-timeline-item-duration">
          {blok.duration}
        </div>
      </div>
      <div
        ref={contentRef}
        className={`copy-default c6-timeline-item-text ${isExpanded ? "expanded" : ""}`}
      >
        {blok.text &&
          blok.text.content.map((content, index) => (
            <RichTextParser key={index}>{content}</RichTextParser>
          ))}
      </div>
      <img
        src={arrowRight}
        alt="arrow right"
        className={`arrow-right c6-timeline-item-arrow ${isExpanded ? "active" : ""}`}
      />
    </div>
  );
};

export default C6TimelineItem;