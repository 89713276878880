import clsx from "clsx";
import { useEffect, useState } from "react";

const C4Image = ({ blok, className }: BASE.C4ImageProps) => {
  const [screenWidth, setScreenWidth] = useState(0);
  const { alt, circleImage = false, src, srcMobile, maxWidth } = blok;
  const imageClass = clsx("c4-image", className, {
    ["circle-image"]: circleImage,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <img
      style={{ maxWidth: maxWidth ? `${maxWidth}px` : "auto" }}
      className={imageClass}
      src={screenWidth < 767 && srcMobile?.filename ? srcMobile.filename : src.filename}
      alt={alt}
    />
  );
};
export default C4Image;
