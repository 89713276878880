import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

type P2ArticleProps = {
  blok: {blocks: Array<BASE.Modules>};
};

const P2Article = ({ blok }: P2ArticleProps) => {
  return (
    <div {...storyblokEditable(blok)} className="px-4 grid-container">
      {blok.blocks
        ? blok.blocks.map((blok: any) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </div>
  );
};
export default P2Article;
