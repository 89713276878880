import clsx from "clsx";

const C7Video = ({ blok }: { blok: BASE.C7VideoProps }) => {
  const { src, alt } = blok;
  if (!blok.aspectRatio) {
    blok.aspectRatio = "16/9";
  }

  // Check if the src is a YouTube URL and convert it to an embeddable URL
  const getEmbedUrl = (url: string) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(youtubeRegex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return url;
  };

  return (
    <div className="c7-video-wrapper grid-margin">
      <iframe
        className={clsx("c7-video", [
          {
            "aspect-16-9": blok.aspectRatio === "16/9",
            "aspect-4-3": blok.aspectRatio === "4/3",
            "aspect-3-4": blok.aspectRatio === "3/4",
          },
        ])}
        src={getEmbedUrl(src)}
        title={alt}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default C7Video;
