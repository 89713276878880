import clsx from "clsx";

const Button = ({ blok, disabled, onClick }: BASE.ButtonProps) => {
  if (!blok.variant) {
    blok.variant = "primary";
  }

  if (!blok.color) {
    blok.color = "darkblue";
  }

  const buttonClass = clsx("library-button", {
    ["library-button-primary-darkblue"]:
      blok.variant === "primary" && blok.color === "darkblue",
    ["library-button-primary-white"]:
      blok.variant === "primary" && blok.color === "white",
    ["library-button-secondary-darkblue"]:
      blok.variant === "secondary" && blok.color === "darkblue",
    ["library-button-secondary-white"]:
      blok.variant === "secondary" && blok.color === "white",
    ["library-button-disabled"]: !!disabled,
  });

  return (
    <>
      {blok.link &&
        blok.link.map((content: BASE.LinksProps) => {
          let url = "";
          switch (content.link.linktype) {
            case "url":
              url = `https://${content.link.url}`;
              break;
            case "story":
              url = content.link.cached_url ? content.link.cached_url : content.link.url;
              break;
            default:
              url = content.link.url;
          }
          return (
            <a
              href={url}
              style={{ pointerEvents: disabled ? "none" : "auto" }}
              className={buttonClass}
              onClick={onClick}
              target={!!content.openInNewTab ? "_blank" : "_self"}
            >
              {content.label}
            </a>
          );
        })}
    </>
  );
};

export default Button;
