/* eslint-disable react/display-name */
import React from "react";

export default function RichTextParser({ children }: any) {
  function renderChildren() {
    const content: BASE.Content[] = children.content;
    if (!content) {
      return null;
    }
    switch (children.type) {
      case "paragraph":
        return (
          <p>
            {content.map((item, index) => {
              if (!item.marks) {
                item.marks = [];
              }
              switch (item.marks[0]?.type) {
                case "bold":
                  return <strong key={index}>{item.text}</strong>;
                case "italic":
                  return <em key={index}>{item.text}</em>;
                case "underline":
                  return <u key={index}>{item.text}</u>;
                case "link":
                  return (
                    <a
                      href={item.marks[0].attrs?.href}
                      target={item.marks[0].attrs?.target}
                    >
                      {item.text}
                    </a>
                  );
                default:
                  return item.text;
              }
            })}
          </p>
        );
        break;
      case "ordered_list":
        return (
          <ol>
            {content.map((item, index) => {
              if (item.content.length > 0) {
                const innerContent = item.content[0].content;
                return (
                  <>
                    {innerContent && innerContent.length > 0 && (
                      <li key={index}>{innerContent[0].text}</li>
                    )}
                  </>
                );
              } else {
                return null;
              }
            })}
          </ol>
        );
      case "bullet_list":
        return (
          <ul>
            {content.map((item, index) => {
              if (item.content.length > 0) {
                const innerContent = item.content[0].content;
                return (
                  <div style={{textAlign: 'left'}}>
                    {innerContent && innerContent.length > 0 && (
                      <li key={index}>{innerContent[0].text}</li>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </ul>
        );
      default:
    }
  }

  return <div>{renderChildren()}</div>;
}
