import { useEffect, useState } from "react";
import env from "react-dotenv";
import StoryblokClient from "storyblok-js-client";

const F2Footer = () => {
  const [data, setData] = useState<BASE.F2FooterProps["blok"]>();

  const Storyblok = new StoryblokClient({
    accessToken: env.STORYBLOK_TOKEN,
  });

  function fetchStory() {
    Storyblok.get("cdn/stories/global/f2footer", {
      version: env.STORYBLOK_IS_PREVIEW === "yes" ? "draft" : "published",
    })
      .then((response) => {
        setData(response.data.story.content);
        if (!response.data.story.content) {
          return console.log("no response.data.story.content");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchStory();
  }, []);

  if (!data) {
    return <></>;
  }

  return (
    <footer>
      <div className="f2-footer">
        {data.navigationLinks.map((link: BASE.LinksProps, index: number) => (
          <a key={index} className="copy-default" href={link.label}>
            {link.label}
          </a>
        ))}
      </div>
    </footer>
  );
};
export default F2Footer;
