import React, { useEffect, useState } from "react";
import "./system/theme/fonts.css";
import "./system/theme/app.css";
import { StoryblokComponent, useStoryblok } from "@storyblok/react";
import F1Header from "./components/modules/function/F1Header";
import F2Footer from "./components/modules/function/F2Footer";
import F5EntryCard from "./components/modules/function/F5EntryCard";

import env from "react-dotenv";
import Lenis from "lenis";

function App() {
  const [hideArrow, setHideArrow] = useState(false);
  const [overlayClose, setOverlayClose] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  let slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

  const story = useStoryblok(slug, {
    version: env.STORYBLOK_IS_PREVIEW === "yes" ? "draft" : "published",
  });

  const lenis = new Lenis({
    autoRaf: true,
  });

  const handleOverlayClick = () => {
    window.scrollTo(0, 0);
    localStorage.setItem("overlayClose", "true");
    localStorage.setItem("visited", "true");

    setHideArrow(true);
    setTimeout(() => {
      setOverlayClose(true);
    }, 500);
    setTimeout(() => {
      setShowOverlay(false);
    }, 1300);
  };

  useEffect(() => {
    const visited = localStorage.getItem("visited");
    const overlayIsClosed = localStorage.getItem("overlayClose");
    const navigation = performance.getEntriesByType("navigation");
    const navigationEntry = navigation[0] as PerformanceNavigationTiming;
    if (!overlayIsClosed && !visited) {
      setHideArrow(false);
      setShowOverlay(true);
      setOverlayClose(false);
    } else {
      setHideArrow(true);
      setShowOverlay(false);
      setOverlayClose(true);
      if (navigationEntry.type === "reload") {
        localStorage.removeItem("overlayClose");
      }
    }
  }, [slug]);

  useEffect(() => {
    lenis.on("scroll", () => null);
  }, []);

  useEffect(() => {
    if (!hideArrow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [hideArrow]);

  useEffect(() => {
    if (story.content) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [story]);

  if (!story || !story.content) {
    return <></>;
  }

  return (
    <>
      {showOverlay && (
        <F5EntryCard
          onClick={handleOverlayClick}
          overlayClose={overlayClose}
          hideArrow={hideArrow}
        />
      )}
      {hideArrow && (
        <div className={`main-content-wrapper ${isLoaded ? "fade-in" : ""}`}>
          <F1Header />
          <div className="main-content">
            <StoryblokComponent blok={story.content} />
          </div>
          <F2Footer />
        </div>
      )}
    </>
  );
}

export default App;
